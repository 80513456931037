// admin/src/Component/EnhancedConversion/Edit.js

import React from 'react';
import {
    regex,
    Edit,
    SimpleForm,
    TextInput,
    required,
    BooleanInput,
    TextField,
    ReferenceInput,
    SelectInput
} from 'react-admin';

const validateDomain = regex(/^[a-zA-Z0-9](?:[a-zA-Z0-9-.]{0,61}[a-zA-Z0-9])?\.[a-zA-Z]{2,}$/, 'Must be a valid domain. Do not add www or http prefix. Subdomains should be converted to main domain.');
const requiredDomain = [required(), validateDomain];

export const EnhancedConversionEdit = (props) => {

    return (
    <Edit title="Edit enhanced conversion " { ...props }>
        <SimpleForm>

            <TextField source="id" />

            <BooleanInput source="active" label="Active" defaultValue={true} />
            <TextInput source="name" label="Name" validate={ required() } />
            <TextInput source="url" label="URL" validate={ requiredDomain } />
            <TextInput source="data_layer" label="Data layer" validate={ required() } />

            <ReferenceInput label="SameSite" source="cookie_consent_same_site" reference="cookie-consent-same-sites" validate={ required() }>
                <SelectInput optionText="name" />
            </ReferenceInput>

        </SimpleForm>
    </Edit>
    );
};
